import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Image, Card } from "react-bootstrap";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Snackbar,
} from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTheme } from "@mui/material/styles";
import { getApi, getFormApi, ImageUrl } from "../services";
import { setUser } from "../slices/userSlice";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Profile_new = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const getapi = getApi;
  const getFormapi = getFormApi();
  // State for form values and errors
  const [fullname, setFullname] = useState(user.Name);
  const [mobile, setMobile] = useState(user.MobileNo);
  const [email, setEmail] = useState(user.MailId);
  const [username, setUsername] = useState(user.username);
  const [password, setPassword] = useState(user.password);
  const [fullnameError, setFullnameError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  // Add state for photo
  const [selectedFile, setSelectedFile] = useState(null); // Provide a type annotation for selectedFile
  const [photoAdded, setPhotoAdded] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const fileInputRef = useRef(null);

  // State for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const CheckMobile = () => {
    const originalNumber = user.MobileNo;

    var modifiedNumber = originalNumber;

    if (originalNumber.startsWith("61")) {
      modifiedNumber = originalNumber.slice(2);
      setMobile(modifiedNumber);
    } else {
      setMobile(user.MobileNo);
    }
  };

  useEffect(() => {
    CheckMobile();
    // console.log('mobile no',mobileno)
  }, [user.MobileNo]);

  const handleAddPhoto = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files ? event.target.files[0] : null;
    setSelectedFile(file);
    Changephoto(file);
    // Preview the selected image
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewUrl(null);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);

    // Reset errors
    setFullnameError(false);
    setMobileError(false);
    setEmailError(false);
    setUsernameError(false);
    setPasswordError(false);

    // Validate required fields
    let hasError = false;
    if (fullname.trim() === "") {
      setFullnameError(true);
      hasError = true;
    }
    if (username.trim() === "") {
      setUsernameError(true);
      hasError = true;
    }
    if (password.trim() === "") {
      setPasswordError(true);
      hasError = true;
    }

    // if (!/^\+?\d{10,}$/i.test(mobile)) {
    //   setMobileError(true);
    //   hasError = true;

    // }
    // Mobile number validation (must be 9 digits)
    if (!/^\d{9}$/.test(mobile)) {
      setMobileError(true);
      setSnackbarMessage("Mobile number must be 9 digits only");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      hasError = true;
    }

    if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
    }

    if (hasError) {
      setSnackbarMessage("Please fill in all required fields correctly.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setSubmitting(false);
      return;
    }

    const formData = new FormData();
    formData.append("image_path", selectedFile ? selectedFile : "");
    formData.append("id", user.Id);
    formData.append("username", fullname);
    formData.append("mail_id", email);
    // formData.append("mobile_no", mobile ? "+61" + mobile : "");
    // formData.append("mobile_no", mobile ? `+61${mobile}` : "");
    formData.append("mobile_no", mobile);
    formData.append("field_username", username);
    formData.append("field_password", password);

    getFormapi
      .post("haloprofileupdate/", formData)
      .then((res) => {
        setSubmitting(false);
        if (res.data.Success) {
          dispatch(
            setUser({
              email: username,
              password: password,
              ImagePath: res.data.data[0].ImagePath
                ? res.data.data[0].ImagePath.split("/").pop()
                : null,
              MailId: email,
              MobileNo: mobile,
              Name: fullname,
            })
          );

          console.log("Updated Mobile Number:", `+61${mobile}`);

          setSnackbarMessage("Profile updated successfully");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
        }
      })
      .catch((err) => {
        setSubmitting(false);
        setSnackbarMessage("Failed to update profile. Please try again.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });
  };

  const handleSubmitOld = (e) => {
    e.preventDefault();
    setSubmitting(true);
    // Reset all errors
    setFullnameError(false);
    setMobileError(false);
    setEmailError(false);
    setUsernameError(false);
    setPasswordError(false);

    // Validation

    if (fullname.trim() === "") {
      setFullnameError(true);
    }
    // Mobile number validation
    if (!/^\+?\d{10,}$/i.test(mobile)) {
      setMobileError(true);
    }
    // Email validation
    if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
    }
    // Username validation
    if (username.trim() === "") {
      setUsernameError(true);
    }
    // Password validation
    // if (password.length < 8) {
    //   setPasswordError(true);
    // }
    // If there are no errors, log the submitted data

    if (!fullnameError && !mobileError && !emailError && !usernameError) {
      // console.log("Submitted Data:");
      // console.log("Full Name:", fullname);
      // console.log("Mobile:", mobile);
      // console.log("Email:", email);
      // console.log("Username:", username);
      // console.log("Password:", password);

      const formData = new FormData();
      formData.append("image_path", selectedFile ? selectedFile : "");
      formData.append("id", user.Id);
      formData.append("username", fullname);
      formData.append("mail_id", email);
      formData.append("mobile_no", mobile ? +61 + mobile : "");
      formData.append("field_username", username);
      formData.append("field_password", password);

      // console.log("submited data", formData);

      getFormapi
        .post("haloprofileupdate/", formData)
        .then((res) => {
          console.log("profile response:->", res);
          setSubmitting(false);
          if (res.data.Success) {
            dispatch(
              setUser({
                email: username,
                password: password,
                ImagePath: res.data.data[0].ImagePath
                  ? res.data.data[0].ImagePath.split("/").pop()
                  : null,
                MailId: email,
                MobileNo: mobile,
                Name: fullname,
              })
            );
            setSnackbarOpen(true);
            setSnackbarMessage("Profile updated successfully");
            setSnackbarSeverity("success");
          }
        })
        .catch((err) => {
          console.log("error", err);
          setSubmitting(false);
        });
    }
  };

  const Changephoto = (file) => {
    const formData = new FormData();
    formData.append("image_path", file ? file : "");
    formData.append("id", user.Id);
    formData.append("username", fullname);

    //console.log('img file',file)
    getFormapi
      .post("haloprofileimgupdate/", formData)
      .then((res) => {
        // console.log("profile photo response:->", res);

        if (res.data.Success) {
          var img = res.data.data[0].ImagePath.split("/").pop();
          dispatch(
            setUser({ ImagePath: res.data.data[0].ImagePath.split("/").pop() })
          );
          setPhotoAdded(true);
          setSnackbarOpen(true);
          setSnackbarMessage("Profile photo updated successfully");
          setSnackbarSeverity("success");
        }
      })
      .catch((err) => {
        console.log("photo error", err);
      });
  };

  const Removephoto = () => {
    const formData = new FormData();
    formData.append("image_path", "");
    formData.append("id", user.Id);
    formData.append("username", fullname);

    getFormapi
      .post("haloprofileimgupdate/", formData)
      .then((res) => {
        //  console.log("profile photo response:->", res);

        if (res.data.Success) {
          dispatch(setUser({ ImagePath: "" }));
          setPhotoAdded(false);
          setPreviewUrl(null);
          setSnackbarOpen(true);
          setSnackbarMessage("Profile photo removed successfully");
          setSnackbarSeverity("success");
        }
      })
      .catch((err) => {
        console.log("photo error", err);
      });
  };

  return (
    <div className="coddgp_mobile_view">
      <Row>
        <Col md={10} className="mx-auto custom-profile-row">
          <Card className="profile_card">
            <div
              style={{ marginLeft: 20, textAlign: "center" }}
              className="user_photodiv d-flex flex-direction-row align-items-center"
            >
              {previewUrl ? (
                <Image
                  rounded
                  width={90}
                  height={90}
                  className="d-inline-flex align-left username object-fit-cover align-middle profile-img"
                  src={previewUrl}
                  roundedCircle
                />
              ) : (
                <Image
                  rounded
                  width={90}
                  height={90}
                  className="d-inline-flex align-left username object-fit-cover align-middle profile-img"
                  src={
                    user.ImagePath
                      ? ImageUrl + user.ImagePath
                      : require("../assets/images/new_photo.png")
                  }
                  roundedCircle
                />
              )}
              <div className="align-items-center user_right">
                {user.ImagePath ? (
                  <Button
                    onClick={Removephoto}
                    className="changephoto"
                    component="label"
                    variant="outlined"
                    size="small"
                    style={{ textTransform: "capitalize" }}
                  >
                    Remove Photo
                  </Button>
                ) : (
                  <Button
                    onClick={handleAddPhoto}
                    className="changephoto"
                    component="label"
                    variant="outlined"
                    size="small"
                    style={{ textTransform: "capitalize" }}
                  >
                    Add Photo
                  </Button>
                )}
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={handleFileInputChange}
                />
              </div>
            </div>

            <div className="p-4 small_m">
              <form onSubmit={handleSubmit}>
                <div>
                  <FormControl fullWidth error={fullnameError}>
                    <FormLabel className="form-label">Full Name</FormLabel>
                    <OutlinedInput
                      error={fullnameError}
                      value={fullname}
                      className="mt-1 custom-input-p"
                      fullWidth
                      onChange={(e) => setFullname(e.target.value)}
                    />
                    {fullnameError && (
                      <FormHelperText>This field is required</FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className="mt-3">
                  <FormControl fullWidth>
                    <FormLabel className="form-label">Mobile</FormLabel>
                    <OutlinedInput
                      value={mobile}
                      className="mt-1 custom-input-p"
                      fullWidth
                      startAdornment={
                        <InputAdornment position="start">
                          <b>+61</b>
                        </InputAdornment>
                      }
                      inputProps={{
                        maxLength: 9, // Limit input to 9 digits
                      }}
                      // onChange={(e) => setMobile(e.target.value)}
                      onChange={(e) =>
                        setMobile(e.target.value.replace(/\D/g, ""))
                      } // Prevent non-numeric input
                    />
                    {mobileError && (
                      <FormHelperText style={{ color: "red" }}>
                        Invalid mobile number
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className="mt-3">
                  <FormControl fullWidth>
                    <FormLabel className="form-label">Email</FormLabel>
                    <OutlinedInput
                      value={email}
                      className="mt-1 custom-input-p"
                      fullWidth
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {/* {emailError && (
                      <FormHelperText>Invalid email address</FormHelperText>
                    )} */}
                  </FormControl>
                </div>
                <div className="mt-3">
                  <FormControl fullWidth error={usernameError}>
                    <FormLabel className="form-label">Username</FormLabel>
                    <OutlinedInput
                      value={username}
                      className="mt-1 custom-input-p"
                      fullWidth
                      endAdornment={
                        <IconButton>
                          <PersonOutlineIcon />
                        </IconButton>
                      }
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    {usernameError && (
                      <FormHelperText>This field is required</FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className="mt-3">
                  <FormControl fullWidth error={passwordError}>
                    <FormLabel className="form-label">Password</FormLabel>
                    <OutlinedInput
                      value={password}
                      className="mt-1 custom-input-p"
                      fullWidth
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {passwordError && (
                      <FormHelperText>
                        Password must be at least 8 characters long
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className="pt-4">
                  <Button
                    type="submit"
                    className="save-button2"
                    variant="contained"
                  >
                    {submitting ? (
                      <CircularProgress size={24} style={{ color: "#fff" }} />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </div>
              </form>
            </div>
          </Card>
        </Col>
      </Row>
      
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Profile_new;
