import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Cookies from "js-cookie";
import customCloseButtonImage from "../assets/images/close-button1.png";
import moment from "moment";
import customClockImage from "../assets/images/clock.png";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./attendance.css";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import TimeModal from "../components/TimeModal";

export const getApi = () => {
  return axios.create({
    baseURL: "https://roster.coddapps.com.au/api/",
    headers: {
      "Content-Type": "application/json",
      Validate: "y2s4pyj52nzr49jnuxxgqk5jtj28cj",
    },
  });
};

const Attendance = () => {
  const [currentTime, setCurrentTime] = useState(moment().format("hh:mm:ss A"));
  const [currentDate, setCurrentDate] = useState(
    moment().format("dddd, DD MMMM YYYY")
  );

  const [loading, setLoading] = useState(false);
  const [practiceLocation, setPracticeLocation] = useState([]);
  const [selectedOptionPractice, setSelectedOptionPractice] = useState(null);

  // Api call
  const api = getApi();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [unser_uniqueid, setUniqueid] = useState("");
  const [todaycheck, setTodaycheck] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [actionType, setActionType] = useState("");

  // New state for the new modal
  const [showNewModal, setShowNewModal] = useState(false);

  const [showModalDelayed, setShowModalDelayed] = useState(false);

  const [error, setError] = useState("");

  const [rosterData, setRosterData] = useState([]);

  const [rosterDataNew, setRosterDataNew] = useState([]);

  // New state to handle when roster data is not available
  const [rosterNotAvailable, setRosterNotAvailable] = useState(false);
  const [rosterNotAvailableMessage, setRosterNotAvailableMessage] =
    useState("");

  const [isTimeModalOpen, setIsTimeModalOpen] = useState(false);
  const [selectedRosterItem, setSelectedRosterItem] = useState(null);
  // Functions to open and close the Time modal
  // const openTimeModal = () => setIsTimeModalOpen(true);
  const openTimeModal = (rosterItem) => {
    setSelectedRosterItem(rosterItem); // Set the selected roster item
    setIsTimeModalOpen(true);
  };
  const closeTimeModal = () => setIsTimeModalOpen(false);

  // New states to track check-in and check-out status
  const [hasCheckedIn, setHasCheckedIn] = useState(false);
  const [hasCheckedOut, setHasCheckedOut] = useState(true);

  // success message
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  // check in radio button data
  const [radio1DescriptionIn, setRadio1DescriptionIn] = useState("");
  const [radio2DescriptionIn, setRadio2DescriptionIn] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [editFieldValue, setEditFieldValue] = useState("");

  // check in radio button data
  const [radio1DescriptionOut, setRadio1DescriptionOut] = useState("");
  const [radio2DescriptionOut, setRadio2DescriptionOut] = useState("");
  const [selectedOptionOut, setSelectedOptionOut] = useState("");
  const [editFieldValueOut, setEditFieldValueOut] = useState("");

  //After hide section show div states
  const [showTiming, setShowTiming] = useState(false);
  const [checkOutMargin, setcheckOutMargin] = useState(false);
  const [checkOutMarginDiv, setcheckOutMarginDiv] = useState(false);
  const [showTimingAndDivider, setShowTimingAndDivider] = useState(false);
  const [checkedInTime, setCheckedInTime] = useState(null);
  const [checkedOutTime, setCheckedOutTime] = useState(null);
  const [totalHours, setTotalHours] = useState(null);
  const [totalMinutes, setTotalMinutes] = useState(null);
  const [totalSeconds, setTotalSeconds] = useState(null);

  const [statusCheck, setStatusCheck] = useState(0);

  // location state's
  const [position, setPosition] = useState({ latitude: null, longitude: null });
  const [showLocationAlert, setShowLocationAlert] = useState(false);
  const [locationFetched, setLocationFetched] = useState(false);
  const [hospitalLocation, setHospitalLocation] = useState(null);
  const [distanceExceeds, setDistanceExceeds] = useState(false);

  const DIRECTUSERLATLNG = useSelector((state) => state.user.DIRECTUSERLATLNG);

  const fetchLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          // Log the current location to the console
          console.log(
            `User Current Location: Latitude ${latitude}, Longitude ${longitude}`
          );

          // Update the state
          setPosition({ latitude, longitude });
          setLocationFetched(true);
          setShowLocationAlert(false);
        },
        (error) => {
          // Handle error cases
          console.error("Error fetching location:", error.message);
          // alert("Please enable location access to use this feature.");
          // setShowLocationAlert(true);

          // if (!Cookies.get("DIRECTUSERLATLNG")) {
          if (!DIRECTUSERLATLNG) {
            alert("Please enable location access to use this feature.");
            setShowLocationAlert(true);
          }
        }
      );
    } else {
      console.error("Geolocation is not available in your browser.");
    }
  };

  useEffect(() => {
    // Fetch hospital location from cookies
    const latlng = Cookies.get("PRACTICELATLNG");
    console.log("Practice location latlng", latlng);

    if (latlng) {
      const [lat, lng] = latlng.split(",").map(Number);
      setHospitalLocation({ lat, lng });
    }

    // Check if DIRECTUSERLATLNG cookie exists (indicates direct login)
    // const userLatlng = Cookies.get("DIRECTUSERLATLNG");
    if (DIRECTUSERLATLNG) {
      // const [latitude, longitude] = userLatlng.split(",").map(Number);
      const [latitude, longitude] = DIRECTUSERLATLNG.split(",").map(Number);
      console.log("Using DIRECTUSERLATLNG cookie for current location", {
        latitude,
        longitude,
      });
      setPosition({ latitude, longitude });
      setLocationFetched(true);
    } else {
      // Fetch location normally if not direct login
      fetchLocation();
    }

    const intervalId = setInterval(() => {
      if (!locationFetched && !DIRECTUSERLATLNG) {
        // if (!locationFetched && !Cookies.get("DIRECTUSERLATLNG")) {
        fetchLocation();
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [locationFetched, DIRECTUSERLATLNG]);

  useEffect(() => {
    // Check if position is defined before calculating distance
    if (locationFetched && hospitalLocation) {
      const { latitude, longitude } = position;
      // Ensure latitude and longitude are defined
      if (latitude !== null && longitude !== null) {
        const distance = calculateDistance(
          latitude,
          longitude,
          hospitalLocation.lat,
          hospitalLocation.lng
        );
        console.log(`Calculated distance: ${distance} meters`);

        if (distance > 2000) {
          setDistanceExceeds(true);
        } else {
          setDistanceExceeds(false);
        }
      }
    }
  }, [locationFetched, hospitalLocation, position]);

  // Haversine formula to calculate distance between two latitudes and longitudes
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3; // metres
    const φ1 = (lat1 * Math.PI) / 180; // φ in radians
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // in metres
  };

  const handleOpenMap = () => {
    if (position.latitude && position.longitude) {
      const mapUrl = `https://maps.google.com/?q=${position.latitude},${position.longitude}`;
      window.open(mapUrl, "_blank"); // Open in a new tab
    } else {
      console.log("Latitude and longitude are not available in handleOpenMap");
    }
  };

  // const userLatlng = Cookies.get("DIRECTUSERLATLNG");

  // console.log("Redux DIRECTUSERLATLNG:", user.DIRECTUSERLATLNG);

  const practicelatlng = Cookies.get("PRACTICELATLNG");

  useEffect(() => {
    // Run CheckUser and RosterData

    CheckUser();
    RosterData();

    // Set up interval for updating time and date
    const interval = setInterval(() => {
      setCurrentTime(moment().format("hh:mm:ss A"));
      setCurrentDate(moment().format("dddd, DD MMMM YYYY"));
    }, 500);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []); // Add necessary dependencies

  function getCookie(name: string): string | null {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length === 2) {
      return parts.pop()?.split(";").shift() || null;
    }

    return null;
  }

  const CheckUser = async () => {
    const useridV = getCookie("userid");
    const location_idV = getCookie("location_id");

    try {
      const res = await api.post("rosterdatalisttoday/", {
        locationid: location_idV,
        userid: useridV,
      });

      const responseData = res.data.data;
      // console.log("Response Data", responseData);

      if (responseData && responseData.length > 0) {
        const status = responseData[0].Status;
        setTodaycheck(responseData[0]);
        setUniqueid(responseData[0].Id);
        Cookies.set("Uniqueid", responseData[0].Id);
        setStatusCheck(status); // Set statusCheck

        // Maintain original flow for setting checked in/out states
        if (status === 1) {
          setHasCheckedIn(true);
          setHasCheckedOut(false);
        } else if (status === 2) {
          setHasCheckedIn(true);
          setHasCheckedOut(true);
        } else if (status === "") {
          setHasCheckedIn(false);
          setHasCheckedOut(true);
        } else if (status === 0) {
          setHasCheckedIn(false);
          setHasCheckedOut(true);
        } else {
          setHasCheckedIn(false);
          setHasCheckedOut(true);
        }

        // console.log(hasCheckedIn);
        // console.log("is check-in", hasCheckedIn);
        console.log("is check-in status", status);
      } else {
        setHasCheckedIn(false);
        setHasCheckedOut(true);
        console.warn("No data found in response.");
      }
    } catch (err) {
      console.error("Error fetching checked-in time:", err);
    }
  };

  const RosterData = async () => {
    const useridV = getCookie("userid");
    const location_idV = getCookie("location_id");

    try {
      const res = await api.post("rosterdatalist/", {
        locationid: location_idV,
        userid: useridV,
      });

      const rosterData = res.data.data;

      const currentDate = moment().format("YYYY-MM-DD");

      // Filter the roster data for entries matching the current date
      const currentDateEntries = rosterData.filter(
        (entry) =>
          moment(entry.CreatedDate).format("YYYY-MM-DD") === currentDate
      );

      // Log only the Starttime and Endtime of the current date entries
      currentDateEntries.forEach((entry) => {});

      // Log only the current date entries
      // console.log("Current Date Entries:", currentDateEntries);

      if (Array.isArray(rosterData) && rosterData.length > 0) {
        // rosterData.forEach((entry, index) => {
        //   console.log(`Created Date of entry ${index + 1}:`, entry.CreatedDate);
        // });
        setRosterData(rosterData);
        setRosterDataNew(currentDateEntries);
      } else {
        console.warn("No data found in response.");
      }
    } catch (error) {
      console.error("Error fetching checked-in time:", error);
    } finally {
      // console.log("Finished fetching roster data.");
    }
  };

  const handleShowModal = async (type) => {
    setActionType(type);

    const useridV = getCookie("userid");
    const location_idV = getCookie("location_id");

    if (type === "checkin") {
      try {
        // Call the API for check-in
        const res = await api.post("rostercheck/", {
          locationid: location_idV,
          useridid: useridV,
          type: "1",
        });

        if (res.data.data.Ontime == 0) {
          setShowModal(false);
          setActionType("");
          setEditFieldValue("");
          setStatusCheck(1);
          await RosterData();
          await CheckUser();
        } else if (res.data.data.Ontime == 10) {
          setRosterNotAvailable(true);
          setRosterNotAvailableMessage("Roster data not available today");
          setShowModal(true);
          setShowNewModal(false);
        } else if (res.data.data.Ontime == 2) {
          setShowModalDelayed(true);
          setStatusCheck(1);
        } else {
          setShowModal(true);
          setStatusCheck(1);
          setRadio1DescriptionIn(res.data.data.description_1);
          setRadio2DescriptionIn(res.data.data.description_2);
        }
      } catch (error) {
        console.error("Error during check-in:", error);
      }
    } else if (type === "checkout") {
      try {
        const Uniqueid = getCookie("Uniqueid");
        const res = await api.post("rostercheck/", {
          locationid: location_idV,
          useridid: useridV,
          id: Uniqueid,
          type: "2",
        });

        console.log("Checkout", res.data);

        if (res.data.data.Ontime == 0) {
          setShowNewModal(true);
          setShowModal(false);
          setActionType("");
          setEditFieldValue("");
          setEditFieldValueOut("");
          setStatusCheck(2);
          await RosterData();
        } else if (res.data.data.Ontime == 2) {
          setShowModalDelayed(true);
          setShowNewModal(false);
        } else {
          setShowModal(true);
          setShowNewModal(false);
          setRadio1DescriptionOut(res.data.data.description_1);
          setRadio2DescriptionOut(res.data.data.description_2);
        }
      } catch (error) {
        console.error("Error during check-out:", error);
      }
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowModalDelayed(false);
    setActionType("");
    setEditFieldValue("");
    setEditFieldValueOut("");
    setError("");
  };

  const handleSubmit = async () => {
    const useridV = getCookie("userid");
    const location_idV = getCookie("location_id");

    // Check - in
    if (selectedOption === "option2" && !editFieldValue) {
      alert("Please provide a description for your check-in.");
      return;
    }

    const reasonid = selectedOption === "option1" ? 1 : 2;
    const checkinDescription =
      selectedOption === "option2" ? editFieldValue : "";

    // Check - out
    if (selectedOptionOut === "option2" && !editFieldValueOut) {
      alert("Please provide a description for your check-out.");
      return;
    }

    const checkinDescriptionOut =
      selectedOptionOut === "option2" ? editFieldValueOut : "";

    try {
      if (actionType === "checkin") {
        console.log("Checked In");

        // Call the API for check-in
        const res = await api.post("rostercheck/", {
          // locationid: user.LocationId,
          // useridid: user.UserId,
          locationid: location_idV,
          useridid: useridV,
          type: "3",
          reasonid,
          checkin_description: checkinDescription,
        });

        console.log("Check-In Response:", res.data);
        // Refresh the user's check-in status after successful submission
        // Hide form elements and show the success message
        setFormSubmitted(true);
        setShowSuccessMessage(true);
        await CheckUser();
        await RosterData();
      } else if (actionType === "checkout") {
        console.log("Checked Out");

        // Call the API for check-out
        const Uniqueid = getCookie("Uniqueid");
        const res = await api.post("rostercheck/", {
          // locationid: user.LocationId,
          // useridid: user.UserId,
          locationid: location_idV,
          useridid: useridV,
          type: "4",
          reasonid,
          // id: unser_uniqueid,
          id: Uniqueid,
          checkin_description: checkinDescriptionOut,
        });

        // setShowTimingAndDivider(true);

        console.log("Check-Out Response:", res.data);
        console.log("Check-Out Response:", res.data.data);

        // Hide form elements and show the success message
        setFormSubmitted(true);
        setShowSuccessMessage(true);

        setStatusCheck(2);
        await CheckUser();
        await RosterData();
      }

      // Show the success message and close the modal after a short delay
      setTimeout(() => {
        handleCloseModal(); // Close the modal after 2 seconds
      }, 2000);
    } catch (error) {
      console.error("Error during check-in/check-out:", error);
    }
  };

  const handleSubmitDelayed = async () => {
    const useridV = getCookie("userid");
    const location_idV = getCookie("location_id");

    // Set `checkinDescriptionDelayed` based on action type
    const checkinDescriptionDelayed =
      actionType === "checkin" ? editFieldValue.trim() : "";
    const checkinDescriptionOutDelayed =
      actionType === "checkout" ? editFieldValue.trim() : "";

    const reasonid = selectedOption === "option1" ? 1 : 2;

    // Validate `checkinDescriptionDelayed` for check-in or `checkinDescriptionOutDelayed` for check-out
    if (actionType === "checkin" && !checkinDescriptionDelayed) {
      setError("Please provide a description for delayed check-in.");
      return;
    } else if (actionType === "checkout" && !checkinDescriptionOutDelayed) {
      setError("Please provide a description for early check-out.");
      return;
    }

    try {
      if (actionType === "checkin") {
        console.log("Checked In Delayed");

        // Call the API for delayed check-in
        const res = await api.post("rostercheck/", {
          // locationid : user.LocationId,
          // useridid : user.UserId,
          locationid: location_idV,
          useridid: useridV,
          type: "5",
          // reasonid,
          checkin_description: checkinDescriptionDelayed,
        });

        console.log("Check-In Response:", res.data);

        setFormSubmitted(true);
        setShowSuccessMessage(true);
        await CheckUser();
        await RosterData();
      } else if (actionType === "checkout") {
        console.log("Checked Out");

        // Call the API for delayed check-out
        const Uniqueid = getCookie("Uniqueid");
        const res = await api.post("rostercheck/", {
          // locationid: user.LocationId,
          // useridid: user.UserId,
          locationid: location_idV,
          useridid: useridV,
          type: "6",
          // reasonid,
          id: Uniqueid,
          // id: unser_uniqueid,
          checkin_description: checkinDescriptionOutDelayed,
        });

        console.log("Check-Out Response:", res.data);

        // Hide form elements and show the success message
        setFormSubmitted(true);
        setShowSuccessMessage(true);
        setStatusCheck(2);
        await CheckUser();
        await RosterData();
      }

      // Show success message and close the modal after a short delay
      setTimeout(() => {
        handleCloseModal(); // Close the modal after 2 seconds
      }, 2000);
    } catch (error) {
      console.error("Error during check-in/check-out:", error);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return;
    const [date] = dateString.split(" ");
    return date;
  };

  const formatTime = (dateString) => {
    if (!dateString) return;
    const [, time] = dateString.split(" ");
    return time;
  };

  const Divider = () => {
    return (
      <div style={{ borderTop: "1px solid #d2d2d2", width: "100%" }}></div>
    );
  };

  const buttonStyle = {
    backgroundColor: "#E6E6E5",
    borderColor: "#E6E6E5",
    color: "#B1B1B1",
    width: "120px",
    padding: "10px",
  };

  const fetchPracticeLocations = async () => {
    // const useridV = getCookie("userid");
    // const userlocation_idV = getCookie("userlocation_Id");
    const userlocation_idV = getCookie("UNIQUEID");
    const location_idV = getCookie("location_id");

    try {
      setLoading(true);

      const res = await api.post("Timesheet/", {
        locationid: location_idV,
        userid: userlocation_idV,
        requesttype: "10",
      });

      if (Array.isArray(res.data.data)) {
        const locations = res.data.data.map((loc) => {
          console.log("Fetched location name:", loc.Locationname);
          console.log("Fetched location id:", loc.location);
          console.log("Fetched location user id:", loc.Userid);
          // return { label: loc.Locationname };
          return {
            label: loc.Locationname,
            location_id: loc.location,
            Userid: loc.Userid,
          };
        });

        setPracticeLocation(locations);
        // Check if there is a default practice saved in cookies
        const savedLocationId = Cookies.get("location_id");
        const defaultLocation = locations.find(
          (loc) => loc.location_id === savedLocationId
        );

        if (defaultLocation) {
          setSelectedOptionPractice(defaultLocation);
        }
        console.log("All fetched locations:", locations);
      } else {
        console.error("Unexpected data format:", res.data);
      }
    } catch (error) {
      console.error("Error fetching practice locations:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPracticeLocations();
  }, []);

  const handleOptionChange = async (event, value) => {
    setLoading(true);
    setSelectedOptionPractice(value);

    if (value) {
      Cookies.set("userid", value.Userid);
      Cookies.set("location_id", value.location_id);
      // console.log("Selected Location Name:", value.label);
    }

    await new Promise((resolve) => setTimeout(resolve, 1000));
    setLoading(false);
    window.location.reload();
  };

  return (
    <>
      <Row>
        <Col md={1}></Col>
        <Col md={10} className="custom-attendance-row">
          {practiceLocation.length > 1 && (
            <Card>
              <Card.Body
                style={{
                  fontWeight: "bold",
                  flex: "0",
                  fontSize: "18px",
                  marginLeft: "15px",
                }}
              >
                <Row>
                  <Col style={{ textAlign: "left" }}>
                    Select Practice Location
                  </Col>
                </Row>
              </Card.Body>
              <Divider />

              <Card
                className="content-card-practice"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Card.Body>
                  <Autocomplete
                    disablePortal
                    options={practiceLocation}
                    onChange={handleOptionChange}
                    value={selectedOptionPractice}
                    className="autocomplete-container"
                    renderOption={(props, option) => (
                      <li {...props}>
                        <span className="option-text">{option.label}</span>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Practice Location" />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.location_id === value?.location_id
                    }
                  />
                </Card.Body>
              </Card>
            </Card>
          )}

          <Card className="main-card">
            <Card.Body
              style={{
                color: "#1959BC",
                fontWeight: "bold",
                flex: "0 0 auto",
                fontSize: "18px",
                marginLeft: "15px",
              }}
            >
              <Row>
                <Col style={{ textAlign: "left" }}>Today</Col>
              </Row>
            </Card.Body>
            <Divider />

            <Card className="content-card">
              <Card.Body>
                {statusCheck !== 2 && (
                  <div className="text-center">
                    <div className="mt-3">
                      <h2 className="mt-3 fw-bold" style={{ color: "#1959BC" }}>
                        {currentTime}
                      </h2>
                      <p className="mt-3">{currentDate}</p>
                    </div>

                    <div className="mt-1 d-flex justify-content-center">
                      <Button
                        className="me-2"
                        onClick={() => {
                          const distance = calculateDistance(
                            position.latitude,
                            position.longitude,
                            hospitalLocation.lat,
                            hospitalLocation.lng
                          );

                          if (distance > 2000) {
                            setDistanceExceeds(true);
                          } else {
                            handleShowModal("checkin");
                          }
                        }}
                        disabled={hasCheckedIn}
                        style={hasCheckedIn ? buttonStyle : {}}
                      >
                        Check-In
                      </Button>
                      <Button
                        onClick={() => {
                          const distance = calculateDistance(
                            position.latitude,
                            position.longitude,
                            hospitalLocation.lat,
                            hospitalLocation.lng
                          );

                          if (distance > 2000) {
                            setDistanceExceeds(true);
                          } else {
                            handleShowModal("checkout");
                          }
                        }}
                        disabled={hasCheckedOut}
                        style={hasCheckedOut ? buttonStyle : {}}
                      >
                        Check-Out
                      </Button>
                    </div>
                  </div>
                )}

                {/* statusCheck === 2 true */}
                {statusCheck === 2 && (
                  <div>
                    <div
                      style={{
                        marginTop: checkOutMargin ? "0rem" : "0rem",
                      }}
                    >
                      {showTimingAndDivider && <Divider />}

                      <div
                        className="container"
                        style={{
                          marginTop: checkOutMarginDiv ? "0rem" : "1rem",
                        }}
                      >
                        {rosterDataNew.map((entry, index) => (
                          <div className="row">
                            <div className="col-lg-4 col-4 d-flex text-start rosterdatanew-firstdiv">
                              <img
                                src={customClockImage}
                                alt="Clock"
                                style={{ width: "32px", height: "32px" }}
                              />
                              <div>
                                <Typography className="showtime-section">
                                  {formatTime(entry.Starttime)}
                                </Typography>
                                <Typography className="showtime-section2">
                                  Check-in
                                </Typography>
                              </div>
                            </div>
                            <div className="col-lg-4 col-4 d-flex rosterdatanew-seconddiv">
                              <img
                                src={customClockImage}
                                alt="Clock"
                                style={{ width: "32px", height: "32px" }}
                              />
                              <div>
                                <Typography className="showtime-section">
                                  {formatTime(entry.Endtime)}
                                </Typography>
                                <Typography className="showtime-section2">
                                  Check-out
                                </Typography>
                              </div>
                            </div>
                            <div className="col-lg-4 col-4 d-flex rosterdatanew-thriddiv">
                              <img
                                src={customClockImage}
                                alt="Clock"
                                style={{ width: "32px", height: "32px" }}
                              />
                              <div>
                                <Typography className="showtime-section">
                                  {entry.Totaltime}
                                </Typography>
                                <Typography className="showtime-section2">
                                  Total Hrs
                                </Typography>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>

            <div>
              <div className="m-3">
                <h6 style={{ color: "#525252", fontWeight: "bold" }}>
                  History
                </h6>
              </div>

              <Card className="history-card-card">
                <Card.Body className="head-history-card">
                  <div className="container">
                    <div className="row">
                      <div className="col-3 custom-col text-center">Date</div>
                      <div className="col-3 custom-col text-center">
                        Start Time
                      </div>
                      <div className="col-3 custom-col text-center">
                        End Time
                      </div>
                      <div className="col-2 custom-col text-center">Total</div>
                      <div className="col-1 custom-col text-center"></div>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <div className="mb-4">
                {rosterData.map((rosterItem, index) => (
                  <Card className="history-card" key={index}>
                    <Card.Body>
                      <div className="container">
                        <div className="row">
                          <div className="col-3 custom-col text-center">
                            {formatDate(rosterItem.CreatedDate)}
                          </div>
                          <div className="col-3 custom-col text-center">
                            {formatTime(rosterItem.Starttime)}
                          </div>
                          <div className="col-3 custom-col text-center">
                            {formatTime(rosterItem.Endtime)}
                          </div>
                          <div className="col-2 custom-col text-center">
                            {rosterItem.Totaltime}
                          </div>
                          <div className="col-1 custom-col text-center">
                            {rosterItem.Status === 2 && (
                              <BsThreeDotsVertical
                                onClick={() => openTimeModal(rosterItem)}
                                className="dots-icon"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                ))}
              </div>

            </div>
            
          </Card>
        </Col>
        <Col md={1}></Col>
      </Row>

      <TimeModal
        show={isTimeModalOpen}
        handleClose={closeTimeModal}
        selectedItem={selectedRosterItem}
      />

      {/* Check-In and Check-Out modal*/}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Modal.Title style={{ fontSize: "18px" }}>
            {actionType === "checkin" && !rosterNotAvailable
              ? "Check-In Confirmation"
              : actionType === "checkout"
              ? "Check-Out Confirmation"
              : rosterNotAvailable
              ? "Notice"
              : ""}
          </Modal.Title>
          <img
            src={customCloseButtonImage}
            alt="Close"
            onClick={handleCloseModal}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>

        <Modal.Body>
          {rosterNotAvailable ? (
            <p className="text-center">{rosterNotAvailableMessage}</p>
          ) : !formSubmitted ? (
            <>
              <p className="text-center">
                {actionType === "checkin"
                  ? "Please select the reason for your check-in:"
                  : "Check-out confirmation."}
              </p>

              {actionType === "checkin" && (
                <div className="justify-content-center mt-3 mb-3">
                  <Form.Group style={{ width: "53%" }} className="mx-auto">
                    <Form.Check
                      type="radio"
                      id="radio1"
                      name="checkInOption"
                      value="option1"
                      label={radio1DescriptionIn}
                      onChange={() => setSelectedOption("option1")}
                    />
                    <Form.Check
                      type="radio"
                      id="radio2"
                      name="checkInOption"
                      value="option2"
                      label={radio2DescriptionIn}
                      onChange={() => setSelectedOption("option2")}
                    />
                  </Form.Group>
                  {selectedOption === "option2" && (
                    <Form.Group
                      controlId="editField"
                      style={{ width: "53%" }}
                      className="mx-auto mt-3"
                    >
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Optional"
                        value={editFieldValue}
                        onChange={(e) => setEditFieldValue(e.target.value)}
                      />
                    </Form.Group>
                  )}
                </div>
              )}

              {actionType === "checkout" && (
                <div className="justify-content-center mt-3 mb-3">
                  <Form.Group style={{ width: "53%" }} className="mx-auto">
                    <Form.Check
                      type="radio"
                      id="radio1"
                      name="checkInOption"
                      value="option1"
                      label={radio1DescriptionOut}
                      onChange={() => setSelectedOptionOut("option1")}
                    />
                    <Form.Check
                      type="radio"
                      id="radio2"
                      name="checkInOption"
                      value="option2"
                      label={radio2DescriptionOut}
                      onChange={() => setSelectedOptionOut("option2")}
                    />
                  </Form.Group>
                  {selectedOptionOut === "option2" && (
                    <Form.Group
                      controlId="editField"
                      style={{ width: "53%" }}
                      className="mx-auto mt-3"
                    >
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Optional"
                        value={editFieldValueOut}
                        onChange={(e) => setEditFieldValueOut(e.target.value)}
                      />
                    </Form.Group>
                  )}
                </div>
              )}
            </>
          ) : (
            // Show success message after form submission
            <div className="success-message text-center">
              {actionType === "checkin"
                ? "Check-in successfully created!"
                : "Check-out successfully created!"}
            </div>
          )}
        </Modal.Body>

        {!formSubmitted && !rosterNotAvailable && (
          <div className="justify-content-center mt-2 mb-3">
            <div className="d-flex justify-content-center">
              <Button
                variant="primary"
                onClick={handleSubmit}
                style={{
                  width: "80px",
                  marginRight: "5px",
                  backgroundColor: "#1959BC",
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </Modal>

      {/* Check-In and Check-Out modal delayes*/}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModalDelayed}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "18px" }}>
            {actionType === "checkin" ? "Delayed Check-In" : "Early Check-Out"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formEditField">
              <Form.Label>Enter Details</Form.Label>
              <Form.Control
                type="text"
                value={editFieldValue}
                onChange={(e) => setEditFieldValue(e.target.value)}
                // placeholder="Enter details"
                placeholder={
                  actionType === "checkin"
                    ? "Enter details for delayed check-in"
                    : "Enter details for early check-out"
                }
              />
            </Form.Group>
            {error && <p style={{ color: "red" }}>{error}</p>}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmitDelayed}>
            Submit
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Row>
        <Col md={12}>
          <div className="m-1">
            {locationFetched ? (
              <div className="mt-2" id="mapContainer"></div>
            ) : showLocationAlert ? (
              <p className="text-right">
                Please enable location access to use this feature.
              </p>
            ) : (
              <p>Fetching your location...</p>
            )}
          </div>
        </Col>
      </Row> */}

      {distanceExceeds && position.latitude && position.longitude && (
        <Modal
          className="mt-5"
          show={distanceExceeds}
          onHide={() => setDistanceExceeds(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Distance Alert</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              You are more than 2000 meters away from the hospital. Please get
              closer to check in.
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="primary" onClick={handleOpenMap}>
              Open Map
            </Button> */}
            <Button
              variant="secondary"
              onClick={() => setDistanceExceeds(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default Attendance;
