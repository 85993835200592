import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Cookies from "js-cookie";
import "./MapLocation.css";
import { useSelector } from "react-redux";

function MapLocation() {
  const [position, setPosition] = useState({ latitude: null, longitude: null });
  const [showLocationAlert, setShowLocationAlert] = useState(false);
  const [locationFetched, setLocationFetched] = useState(false);
  const [hospitalLocation, setHospitalLocation] = useState(null);
  const [distanceExceeds, setDistanceExceeds] = useState(false);

  const DIRECTUSERLATLNG = useSelector((state) => state.user.DIRECTUSERLATLNG);

  const fetchLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          // Log the current location to the console
          console.log(
            `User Current Location: Latitude ${latitude}, Longitude ${longitude}`
          );

          // Update the state
          setPosition({ latitude, longitude });
          setLocationFetched(true);
          setShowLocationAlert(false);
        },
        (error) => {
          // Handle error cases
          console.error("Error fetching location:", error.message);
          // alert("Please enable location access to use this feature.");
          // setShowLocationAlert(true);

          // if (!Cookies.get("DIRECTUSERLATLNG")) {
          if (!DIRECTUSERLATLNG) {
            alert("Please enable location access to use this feature.");
            setShowLocationAlert(true);
          }
        }
      );
    } else {
      console.error("Geolocation is not available in your browser.");
    }
  };

  useEffect(() => {

    
    
    // Fetch hospital location from cookies
    const latlng = Cookies.get("PRACTICELATLNG");
    console.log("Practice location latlng", latlng);

    

    if (latlng) {
      const [lat, lng] = latlng.split(",").map(Number);
      setHospitalLocation({ lat, lng });
    }

    // Check if DIRECTUSERLATLNG cookie exists (indicates direct login)
    // const userLatlng = Cookies.get("DIRECTUSERLATLNG");
    if (DIRECTUSERLATLNG) {
      // const [latitude, longitude] = userLatlng.split(",").map(Number);
      const [latitude, longitude] = DIRECTUSERLATLNG.split(",").map(Number);
      console.log("Using DIRECTUSERLATLNG cookie for current location", {
        latitude,
        longitude,
      });
      setPosition({ latitude, longitude });
      setLocationFetched(true);
    } else {
      // Fetch location normally if not direct login
      fetchLocation();
    }

    const intervalId = setInterval(() => {
      if (!locationFetched && !DIRECTUSERLATLNG) {
        // if (!locationFetched && !Cookies.get("DIRECTUSERLATLNG")) {
        fetchLocation();
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [locationFetched, DIRECTUSERLATLNG]);


  useEffect(() => {
    // Check if position is defined before calculating distance
    if (locationFetched && hospitalLocation) {
      const { latitude, longitude } = position;
      // Ensure latitude and longitude are defined
      if (latitude !== null && longitude !== null) {
        const distance = calculateDistance(
          latitude,
          longitude,
          hospitalLocation.lat,
          hospitalLocation.lng
        );
        console.log(`Calculated distance: ${distance} meters`);

        if (distance > 2000) {
          setDistanceExceeds(true);
        } else {
          setDistanceExceeds(false);
        }
      }
    }
  }, [locationFetched, hospitalLocation, position]);

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3; // metres
    const φ1 = (lat1 * Math.PI) / 180; // φ in radians
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // in metres
  };

  const handleOpenMap = () => {
    if (position.latitude && position.longitude) {
      const mapUrl = `https://maps.google.com/?q=${position.latitude},${position.longitude}`;
      window.open(mapUrl, "_blank"); // Open in a new tab
    } else {
      console.log("Latitude and longitude are not available in handleOpenMap");
    }
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <div className="m-1">
            {locationFetched ? (
              <div className="mt-2" id="mapContainer"></div>
            ) : showLocationAlert ? (
              <p className="text-right">
                Please enable location access to use this feature.
              </p>
            ) : (
              <p>Fetching your location...</p>
            )}
          </div>
        </Col>
      </Row>

      {distanceExceeds && position.latitude && position.longitude && (
        <Modal
          className="mt-5"
          show={distanceExceeds}
          onHide={() => setDistanceExceeds(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Distance Alert</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              You are more than 2000 meters away from the hospital. Please get
              closer to check in.
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="primary" onClick={handleOpenMap}>
              Open Map
            </Button> */}
            <Button
              variant="secondary"
              onClick={() => setDistanceExceeds(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default MapLocation;
