import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Snackbar,
  Alert,
  FormControl,
  OutlinedInput,
  Typography,
  FormHelperText,
  FormLabel,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";

export const getApi = () => {
  return axios.create({
    baseURL: "https://roster.coddapps.com.au/api/",
    headers: {
      "Content-Type": "application/json",
      Validate: "y2s4pyj52nzr49jnuxxgqk5jtj28cj",
    },
  });
};

const Devicelocation = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/attendancasasa");
    console.log('log.....');
  });


  return (
    <>
      <div className="login-web-view">
        <Row className="login-row">
          <Col md={10} className="mx-auto">
            <div className="login-div">
              <Card className="login-div-web">

              </Card>
            </div>
          </Col>
        </Row>
      </div>

    </>
  );
};

export default Devicelocation;
