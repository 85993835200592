import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Snackbar,
  Alert,
  FormControl,
  OutlinedInput,
  Typography,
  FormHelperText,
  FormLabel,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { login, selectAuth } from "../slices/authSlice";
import { useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { setUser } from "../slices/userSlice";
import axios from "axios";
import Cookies from "js-cookie";
import "./login.css";

export const getApi = () => {
  return axios.create({
    baseURL: "https://roster.coddapps.com.au/api/",
    headers: {
      "Content-Type": "application/json",
      Validate: "y2s4pyj52nzr49jnuxxgqk5jtj28cj",
    },
  });
};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getapi = getApi();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleload,setisVisibleload] =useState(true);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const { isAuthenticated } = useSelector(selectAuth);
  const user = useSelector((state) => state.user);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [latlngV, setlatlngV] = useState("");
  const [passwordV, setpasswordV] = useState("");
  const theme = useTheme();
  const location = useLocation();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  // console.log("Redux details log", user);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError: setFormError,
    clearErrors,
  } = useForm();

  useEffect(() => {
    fetchPracticeLocations();
    // const firstTimeLogin = Cookies.get("firstTimeLogin");
    if (isAuthenticated) {
      if (isAuthenticated) {
        navigate("/attendance");
      }
    }
  }, [isAuthenticated, navigate]);

  // useEffect(() => {
  //   fetchPracticeLocations();
  //   setTimeout(() => {
  //     if (isAuthenticated) {
  //       navigate("/attendance");
  //     }
  //   }, 2500);
  // }, [isAuthenticated, navigate]);

  const fetchPracticeLocations = async () => {
    const currentUrl = window.location.href;
    const urlObj = new URL(currentUrl);

    // Extract query parameters
    const username = urlObj.searchParams.get("username");
    const password = urlObj.searchParams.get("password");
    const latlng = urlObj.searchParams.get("latlng");

    // Validate and log the latlng parameter
    if (username && password && latlng) {
      console.log("Direct login User LatLng:", latlng);
      // Cookies.set("DIRECTUSERLATLNG", latlng);
      setlatlngV(latlng);

      dispatch(setUser({ DIRECTUSERLATLNG: latlng }));
      console.log("Redux state updated - DIRECTUSERLATLNG: First", latlng);
    } else {
      setTimeout(() => {
        setIsVisible(true);
        setisVisibleload(false);
      }, 2500);
      console.warn("LatLng parameter is missing in the URL.");
    }

    // Prepare data for direct login
    if (username && password) {
      const datad = { email: username, password: password };
      console.log("Direct login initiated:", datad);
      await onSubmit(datad);
    } else {
      console.warn("Username or password parameter is missing in the URL.");
    }
  };

  // useEffect(() => {
  //   fetchPracticeLocations();
  // }, []);

  const onSubmit = async (data) => {
    const { email, password } = data;
    // console.log(data);
    if (!email || !password) {
      setFormError("email", { message: "Username is required" });
      setFormError("password", { message: "Password is required" });
      return;
    }

    try {
      const payload = { username: email, password: password };
      const res = await getapi.post("loginuser/", payload);
      // console.log("Login user response data:", res.data);
      // console.log("Login user response data:", res.data.data[0].is_diect);

      dispatch(login(payload));

      const {
        LocationId,
        UserId,
        Id,
        ImagePath,
        MailId,
        MobileNo,
        Name,
        LATLNG,
        firstTimeLogin,
        is_diect,
      } = res.data.data[0];

      dispatch(
        setUser({
          email,
          password,
          Id,
          LocationId,
          UserId,
          ImagePath,
          MailId,
          MobileNo,
          Name,
          LATLNG,
          is_diect,
        })
      );

      Cookies.set("UNIQUEID", Id); // 156233
      Cookies.set("userid", UserId); // 35
      Cookies.set("location_id", LocationId); // S05088ANA
      Cookies.set("PRACTICELATLNG", LATLNG);

      setOpenSnackbar(true);
      setSnackbarSeverity("success");
    } catch (err) {
      setOpenSnackbar(true);
      setSnackbarSeverity("error");
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    clearErrors();
  };

  return (
    <>
      <div className={`login-web-view ${isVisible ? '' : 'hidden'}`}>
        <Row className="login-row">
          <Col md={10} className="mx-auto">
            <div className="login-div">
              <Card className="login-div-web">
                <Row>
                  <Col md={5} sm={6}>
                    <div className="image-container">
                      <img
                        src={
                          matches
                            ? require("../assets/images/Maskgroupmob.png")
                            : require("../assets/images/Maskgroupweb.png")
                        }
                        alt="Background"
                        className="img-fluid"
                      />
                    </div>
                  </Col>

                  <Col md={7} sm={6}>
                    <div>
                      <div className="text-center">
                        <Typography
                          className="first-text-web fw-bold"
                          variant="h5"
                        >
                          Hey, Welcome Back!
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className="mt-3 mb-2 second-text-web"
                        >
                          Login to your Account
                        </Typography>
                      </div>

                      {error && (
                        <Alert severity="error" onClose={() => setError(false)}>
                          Incorrect username or password. Please try again.
                        </Alert>
                      )}

                      {success && (
                        <Alert
                          elevation={60}
                          severity="success"
                          onClose={() => setSuccess(false)}
                        >
                          Login successful!
                        </Alert>
                      )}

                      <div>
                        <form
                          autoComplete="off"
                          onSubmit={handleSubmit(onSubmit)}
                          className="custom-login-form"
                        >
                          <FormControl fullWidth error={!!errors.email}>
                            <FormLabel className="login-label">
                              Username
                            </FormLabel>
                            <OutlinedInput
                              {...register("email")}
                              name="email"
                              type="text"
                              autoComplete="username"
                              className="mt-1 input-border login-form"
                              placeholder="Enter username"
                              sx={{
                                input: {
                                  color: "#000",
                                  fontSize: "16px",
                                  "&::placeholder": {
                                    opacity: 1,
                                  },
                                },
                              }}
                            />

                            {!!errors.email && (
                              <FormHelperText>
                                {errors.email?.message}
                              </FormHelperText>
                            )}
                          </FormControl>

                          <FormControl
                            fullWidth
                            error={!!errors.password}
                            className="mt-3"
                          >
                            <FormLabel className="login-label">
                              Password
                            </FormLabel>
                            <OutlinedInput
                              {...register("password")}
                              name="password"
                              type="password"
                              autoComplete="current-password"
                              className="mt-1 input-border login-form"
                              placeholder="Enter Password"
                              sx={{
                                input: {
                                  color: "#000",
                                  fontSize: "16px",
                                  "&::placeholder": {
                                    opacity: 1,
                                  },
                                },
                              }}
                            />

                            {!!errors.password && (
                              <FormHelperText>
                                {errors.password?.message}
                              </FormHelperText>
                            )}
                          </FormControl>

                          <div className="button-container">
                            <Button
                              onClick={() => navigate("/recover-account")}
                              className="login-forgot"
                            >
                              Forget Password?
                            </Button>
                          </div>
                          <Button
                            type="submit"
                            fullWidth
                            className="login-login-btn"
                            disabled={submitting}
                            variant="contained"
                            sx={{ p: 1 }}
                          >
                            {submitting ? (
                              <CircularProgress
                                style={{ color: "#FFF" }}
                                size={24}
                              />
                            ) : (
                              "Login"
                            )}
                          </Button>

                          <div className="mt-3">
                            <p className="info_login">
                              Don't have an account?
                              <br />
                              Contact{" "}
                              <a
                                className="ahover"
                                href="mailto:support@codd.com.au"
                              >
                                support@codd.com.au
                              </a>{" "}
                              to set up one.
                            </p>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </div>
          </Col>
        </Row>
      </div>

      <div className={`login-loading ${isVisibleload ? '' : 'hidden'}`}>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarSeverity === "success"
            ? "Login successful!"
            : "Incorrect email or password."}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Login;
