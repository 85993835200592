// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hidden{
  display: none !important;
}
.login-loading{
  height: 100rem;
}
.p-login{
  padding-top: 40%;
  text-align: center;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/pages/login.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;AACA;EACE,cAAc;AAChB;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;AACb","sourcesContent":[".hidden{\n  display: none !important;\n}\n.login-loading{\n  height: 100rem;\n}\n.p-login{\n  padding-top: 40%;\n  text-align: center;\n  color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
